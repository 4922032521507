import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { uploadData } from 'aws-amplify/storage';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { ReactComponent as ExcelIcon } from '../../images/icons/File_Type_Excel.svg';
import { ReactComponent as PowerPointIcon } from '../../images/icons/File_Type_Powerpoint.svg';
import { ReactComponent as WordIcon } from '../../images/icons/File_Type_Word.svg';
import { ReactComponent as PdfIcon } from '../../images/icons/File_Type_Pdf.svg';
import { ReactComponent as AltDocIcon } from '../../images/icons/File_Type_Alt.svg';

import { copy } from '../../utils';
import amplifyConfig from '../../utils/amplify';

import { ErrorMessageContext, UserDataContext } from '../../contexts';

import useS3 from '../../hooks/useS3';

import './FileUploadAndInfoContainer.scss';

export default function FileUploadAndInfoContainer({
  uploadType,
  customContainerStyles,
  transactionId,
  filesUploadedParent,
  setFilesUploadedParent,
  updateTransactionData,
}) {
  const [filesList, setFilesList] = useState([]);

  const [fileUploading, setFileUploading] = useState(false);
  const [numberOfFilesUploading, setNumberOfFilesUploading] = useState(0);
  const [fileValue, setFileValue] = useState('');

  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadCallCompleted, setUploadCallCompleted] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [fileDeleted, setFileDeleted] = useState(false);

  const [fileIsDraggingOver, setFileIsDraggingOver] = useState(false);

  const [currentNumberOfFiles, setCurrentNumberOfFiles] = useState(-1);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const { userData } = useContext(UserDataContext);

  const [, s3DeleteRequest] = useS3();

  const uploadEl = useRef(null);
  const dragAndDropAreaRef = useRef(null);

  const uploadDataRef = useRef(filesUploadedParent || []);

  const validFileTypes = ['.xlsx', '.xls', '.xlsm', '.doc', '.docx', '.ppt', '.pptx', '.pps',
    '.ppsx', '.txt', '.pdf', '.csv', '.jpg', '.jpeg', '.heic', '.png', '.odt',
    '.fodt', '.odf', '.fods', '.odp', '.fodp', '.odg', '.fodg', '.odf', '.odb',
    '.sxw', '.stw', '.sxc', '.stc', '.sxi', '.sti', '.sxd', '.std', '.sxm'];


  amplifyConfig({
    Storage: {
      S3: {
        bucket: process.env.REACT_APP_S3_UPLOADS_BUCKET,
        region: process.env.REACT_APP_AWS_REGION,
      },
    },
  });

  useEffect(() => {
    if (filesUploadedParent) {
      uploadDataRef.current = filesUploadedParent;
      setFilesList(filesUploadedParent);
      if (currentNumberOfFiles === -1) setCurrentNumberOfFiles(filesUploadedParent.length);
    }
  }, [filesUploadedParent]);

  async function handleFileUpload(event, filesToUpload) {
    event.preventDefault();
    if (!filesToUpload || !Object.keys(filesToUpload).length) return;
    if (Object.keys(filesToUpload).some((fileToUpload) => uploadDataRef.current.some((file) => file.fileName === filesToUpload[fileToUpload].name))) {
      setShowErrorMessage('One or more files of the same name have already been uploaded');
      return;
    }
    if (Object.keys(filesToUpload).some((file) => (filesToUpload[file].size === 0))) {
      setShowErrorMessage('Invalid file size');
      return;
    }
    if (Object.keys(filesToUpload).some((file) => (filesToUpload[file].size >= 35000000))) {
      setShowErrorMessage('File size is too big');
      return;
    }
    if (!validFileTypes.some((fileType) => (Object.keys(filesToUpload).some((file) => filesToUpload[file].name.toLowerCase().endsWith(fileType))))) {
      setShowErrorMessage('Invalid file(s) type uploaded');
      return;
    }

    try {
      setFileUploading(true);
      setNumberOfFilesUploading(Object.keys(filesToUpload).length);
      const newFileList = copy(uploadDataRef.current);
      Object.keys(filesToUpload).forEach((fileToUpload) => {
        const fileName = filesToUpload[fileToUpload].name;

        const s3Key = `${userData.userAttributes.companyId}/${transactionId}/${uploadType}/${filesToUpload[fileToUpload].name}`;

        newFileList.push({ fileName, s3Key });
      });

      setFilesList(newFileList);
      Promise.all(Object.keys(filesToUpload).map((fileToUpload, fileIndex) => {
        const s3Key =
          `${userData.userAttributes.companyId}/${transactionId}/${uploadType}/${filesToUpload[fileToUpload].name}`;
        return uploadData({
          path: s3Key, data: filesToUpload[fileToUpload], options: {
            onProgress({ transferredBytes, totalBytes }) {
              setUploadProgress((prevProgress) => {
                const newProgress = copy(prevProgress);
                newProgress[fileIndex] = { transferredBytes, totalBytes };
                return newProgress;
              });
            },
          },
        });
      }));
      setUploadCallCompleted(true);
      setFilesUploadedParent(newFileList);
      setTimeout(() => setUploadCompleted(true), 1000);
      setTimeout(() => {
        setUploadCompleted(false);
        setFileUploading(false);
        setUploadProgress([]);
      }, 2500);
    } catch (e) {
      setShowErrorMessage(e.toString());
      setUploadProgress([]);
      setFileUploading(false);
      setFilesList(uploadDataRef.current);
    }
  }

  useEffect(() => {
    if (uploadCompleted || fileDeleted) {
      updateTransactionData();
      setFileDeleted(false);
    }
  }, [uploadCompleted, fileDeleted]);

  useEffect(() => {
    if (uploadCallCompleted) {
      const newProgress = copy(uploadProgress);
      [...Array(numberOfFilesUploading)].forEach((_, fileIndex) => {
        if (!uploadProgress[fileIndex] || uploadProgress[fileIndex].transferredBytes === 0) {
          newProgress[fileIndex] = { transferredBytes: 1, totalBytes: 1 };
        }
      });
      setUploadProgress(newProgress);
      setUploadCallCompleted(false);
    }
  }, [uploadCallCompleted]);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };


  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
    handleFileUpload(e, e.dataTransfer.files);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
  };

  const dragAndDropArea = useCallback((node) => {
    if (dragAndDropAreaRef?.current) {
      dragAndDropAreaRef.current.removeEventListener('dragover', handleDragOver);
      dragAndDropAreaRef.current.removeEventListener('drop', handleDrop);
      dragAndDropAreaRef.current.removeEventListener('dragenter', handleDragEnter);
      dragAndDropAreaRef.current.removeEventListener('dragleave', handleDragLeave);
    }
    if (node) {
      node.addEventListener('dragover', handleDragOver);
      node.addEventListener('drop', handleDrop);
      node.addEventListener('dragenter', handleDragEnter);
      node.addEventListener('dragleave', handleDragLeave);
    }
    dragAndDropAreaRef.current = node;
  }, []);

  async function removeFile(file, fileIndex) {
    s3DeleteRequest({
      requestType: 'remove',
      key: file.s3Key,
      onSuccess: () => {
        const newFileList = copy(filesList);
        newFileList.splice(fileIndex, 1);
        setFilesList(newFileList);
        setFilesUploadedParent(newFileList);
        setFileDeleted(true);
      },
    });
  }

  return (
    <div
      className="file-upload-container"
      style={customContainerStyles}
    >
      <div
        className={`drag-and-drop-area ${fileIsDraggingOver ? 'file-dragging' : ''}`}
        ref={dragAndDropArea}
        // onDrop={handleDrop}
        // onDragOver={handleDragOver} // Attach the drag over handler

      >
        {fileUploading && <div className="file-upload-loading-wrapper"><span className="dots-circle-spinner" /></div>}
        <div className="browse-or-drop">
          <UploadFileIcon />
          <span>Drop files here</span>
          <span>or</span>
          <Button
            onClick={() => {
              setFileValue('');
              uploadEl.current.click();
            }}
            onKeyDown={(e) => { e.stopPropagation(); }}
          >
            Browse
          </Button>
        </div>

        <Tooltip
          title={(
            <>
              <p>Accepted file types:</p>
              <p>
                <span>Excel&nbsp;</span>
                (.xls, .xlsx, .xlsm),&nbsp;
                <span>Word&nbsp;</span>
                (.doc, .docx),&nbsp;
              </p>
              <p>
                <span>PDF, Powerpoint&nbsp;</span>
                (.ppt, .pptx, .pps, .ppsx),&nbsp;
              </p>
              <p>
                <span>Image files&nbsp;</span>
                (.jpg, .jpeg, .heic, .png)&nbsp;
              </p>
            </>
          )}
          PopperProps={{ className: 'file-type-tooltip' }}
          placement="bottom"
          arrow
        >
          <span className="file-type-icons">
            Accepted file types
            <ExcelIcon className="icon" />
            <PdfIcon className="icon" />
            <WordIcon className="icon" />
            <PowerPointIcon className="icon" />
            <AltDocIcon className="icon" />
          </span>
        </Tooltip>
        <input
          ref={uploadEl}
          type="file"
          className="file-style"
          accept={validFileTypes.join(',')}
          value={fileValue}
          multiple="multiple"
          onChange={(e) => { handleFileUpload(e, e.target.files); }}
        />
      </div>
      <div className="uploaded-files">
        {filesList.map((file, fileIndex) => (
          <div
            className={`file${uploadCompleted && fileIndex >= filesList.length - numberOfFilesUploading ? ' completed' : ''}`}
            key={`${file.fileName}-${file.dateTime}`}
          >
            <div className="file-and-progress">
              <div className="file-name">
                {uploadCompleted && fileIndex >= filesList.length - numberOfFilesUploading && <CheckCircleOutlineIcon className="check-icon" />}
                {fileUploading && fileIndex >= filesList.length - numberOfFilesUploading && !uploadCompleted && (
                  <div className="dots-circle-spinner" />
                )}
                {uploadCompleted && fileIndex >= filesList.length - numberOfFilesUploading ? 'Uploaded ' :
                  fileUploading && fileIndex >= filesList.length - numberOfFilesUploading ? 'Uploading ' : ''}
                {(!fileUploading || fileIndex < filesList.length - numberOfFilesUploading) && <InsertDriveFileOutlinedIcon />}
                {(file.fileName.length >= 28) ? `${file.fileName.slice(0, 28).trim()}...` : file.fileName}
              </div>
              {fileUploading && fileIndex >= filesList.length - numberOfFilesUploading && (
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress[fileIndex - (filesList.length - numberOfFilesUploading)] ?
                    ((uploadProgress[fileIndex - (filesList.length - numberOfFilesUploading)].transferredBytes /
                      uploadProgress[fileIndex - (filesList.length - numberOfFilesUploading)].totalBytes) * 100) : 0}
                />
              )}
            </div>
            {!fileUploading && (
              <IconButton
                className="clear-icon"
                onClick={() => removeFile(file, fileIndex)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

FileUploadAndInfoContainer.propTypes = {
  uploadType: PropTypes.string.isRequired,
  customContainerStyles: PropTypes.object,
  transactionId: PropTypes.string.isRequired,
  filesUploadedParent: PropTypes.arrayOf(PropTypes.object),
  setFilesUploadedParent: PropTypes.func.isRequired,
  updateTransactionData: PropTypes.func.isRequired,
};
